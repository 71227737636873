import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Input, Loader } from '@storybook';

import { BodyWrapper, LabelElement } from 'components';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { REACT_APP_LIQUIDITY_DOMAIN as LIQUIDITY_DOMAIN } from 'envs';
import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { API_TYPE } from 'constants/api';
import {
	AccessTokenState,
	WebComponentMetaDataState,
	isShowSkipState,
} from 'states';
import { dolaterSuccess } from 'views/constants';
import { AllowedToDoItLater } from 'constants/kyb-form';

import './kyb-form.scss';

export const KybForm = () => {
	// local states
	const [optionSelected, setOptionSelected] = useState<any>({});
	const [dropOptionFlag, setDropOptionFlag] = useState(false);
	const [bodyElemFlag, setBodyElemFlag] = useState(false);
	const [selectFileName, setSelectFileName] = useState<any>([]);
	const [selectDocName, setselectDocName] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [isSkipLoading, setIsSkipLoading] = useState(false);
	const [anyOtherField, setAnyOtherField] = useState<any>([]);

	// Global states
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	// Destructure `isWebComponent` (defaulting to `false`) and `onComplete` from `WebComponentMetaDataState`
	const { isWebComponent = false, onComplete } = useRecoilValue(
		WebComponentMetaDataState
	);

	// hooks
	const { postTokenSession } = useTokenSession();
	const { getKybForm, nodeIdFromQueryParam } = useSharedVariables();
	const { successNotification, warningNotification, errorNotification } =
		useNotification();
	const { setSessionDetails, sessionPayloadDetail, handleNext } = useNextStep();
	const { currentAction, businessId = '' } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const kybFromEntities = useMemo(() => {
		if (
			sessionDetails?.nodes?.key === 'kybForm' ||
			sessionDetails?.nodes?.actions?.[0]?.key === 'kybForm'
		) {
			return sessionDetails?.nodes?.actions?.[0]?.metadata?.questions ?? [];
		} else {
			return [];
		}
	}, [sessionDetails]);

	const { userId, pipelineId } = sessionDetails;

	const anyOtherDocumentValueLable = useMemo(() => {
		return `Item${optionSelected?.choices?.length + anyOtherField.length + 1}`;
	}, [anyOtherField.length, optionSelected?.choices?.length]);

	// Memoize the check to see if `businessId` is in the `AllowedToDoItLater` array
	const isDoItLater = useMemo(
		() => AllowedToDoItLater.includes(businessId),
		[businessId]
	);

	useEffect(() => {
		if (sessionDetails?.existingKybFormAction) {
			const isChoices = sessionDetails?.existingKybFormAction?.choices;
			const anyOtherDocumentField = Object.keys(isChoices)
				.filter(choices => isChoices[choices].text === 'Any other document')
				.map(key => {
					return {
						value: key,
						text: isChoices[key]?.text,
						docUploaded: isChoices[key]?.docUploaded,
						docPath: isChoices[key]?.docPath,
						fileName: isChoices[key]?.fileName,
					};
				});
			setAnyOtherField(anyOtherDocumentField);
			if (isChoices && typeof isChoices === 'object') {
				const reFactorChoice = Object.keys(isChoices)
					.filter(choices => isChoices[choices].text !== 'Any other document')
					.map(key => {
						return {
							value: key,
							text: isChoices[key]?.text,
							docUploaded: isChoices[key]?.docUploaded,
							docPath: isChoices[key]?.docPath,
							fileName: isChoices[key]?.fileName,
						};
					});
				setOptionSelected({
					choices: reFactorChoice,
					title: sessionDetails?.existingKybFormAction?.title,
				});
				setBodyElemFlag(true);
				const prefilledFiles = reFactorChoice.map(choice => ({
					id: choice.text,
					value: choice.value,
					fileName: choice.fileName || '',
				}));
				setSelectFileName(prefilledFiles as any);
			}
		}
	}, [anyOtherDocumentValueLable, sessionDetails]);

	const handelDropOption = useCallback(
		(key: any) => {
			setDropOptionFlag(true);
			setOptionSelected(key);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dropOptionFlag]
	);

	const handelKybFormNext = useCallback(() => {
		setBodyElemFlag(true);
	}, []);

	const handelKybFormBack = useCallback(() => {
		setBodyElemFlag(false);
		setDropOptionFlag(false);
		setOptionSelected('');
		setSelectFileName([]);
		setSessionDetails(prev => ({ ...prev, existingKybFormAction: {} }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSubmitDisable = useMemo(() => {
		const Checkvalue = selectFileName.filter((data: any) => data?.fileName);
		return !Checkvalue.length;
	}, [selectFileName]);

	const handelKybFormSubmit = useCallback(
		async (isSkipForNow?: boolean, isAllowedToDoItLater?: boolean) => {
			if (!isSkipForNow) {
				setIsLoading(true);
			} else {
				setIsSkipLoading(true);
			}
			const choiceData: any = {};
			if (!isSkipForNow) {
				optionSelected?.choices?.forEach((data: any) => {
					const uploadedFile: any = selectFileName.find(
						(file: any) => file.value === data.value
					);
					if (data?.value) {
						choiceData[data.value] = {
							text: data?.text ?? '',
							answer:
								data?.docPath || (uploadedFile ? uploadedFile.base64 : ''),
							fileName: uploadedFile ? uploadedFile.fileName : '',
						};
						if (data?.docUploaded) {
							choiceData[data.value].docUploaded = data?.docUploaded;
						}
					}
					// Check for "Any other document"
					const anyOtherDocFile: any = selectFileName.find(
						(file: any) => file.value === anyOtherDocumentValueLable
					);
					anyOtherField.map((field: any) => {
						if (field.fileName) {
							choiceData[field.value] = {
								text: 'Any other document',
								answer: field.docPath || '', // Include base64 if exists, else empty
								fileName: field.fileName,
								docUploaded: true,
							};
						}
					});
					if (anyOtherDocFile && anyOtherDocFile.fileName) {
						choiceData[anyOtherDocumentValueLable] = {
							text: 'Any other document',
							answer: anyOtherDocFile ? anyOtherDocFile.base64 : '', // Include base64 if exists, else empty
							fileName: anyOtherDocFile ? anyOtherDocFile.fileName : '',
						};
					}
				});
			} else {
				optionSelected?.choices?.map((data: any) => {
					if (data?.value) {
						choiceData[data.value] = {
							text: data?.text ?? '',
							answer: data?.docPath || '',
							fileName: data?.fileName,
						};
						if (data?.docUploaded) {
							choiceData[data.value].docUploaded = data?.docUploaded;
						}
					}
				});
			}

			const removeColonTitle = optionSelected?.title?.replaceAll(':', '');
			const kybPayload = {
				pipelineId: pipelineId ?? '',
				userId: userId ?? '',
				nodeId: nodeIdFromQueryParam ?? currentAction._id ?? '',
				// Conditionally add `isDoItLater: true` to the object if `isAllowedToDoItLater` is true
				...(isAllowedToDoItLater && { isDoItLater: true }),
				actions: [
					{
						id: 'kybForm',
						data: {
							survey: {
								[optionSelected?.name ?? sessionDetails?.questions]: {
									title: removeColonTitle,
									displayValue: optionSelected.choices
										.map((data: any) => data.text)
										.toString(),
									choices: choiceData,
								},
							},
						},
					},
				],
			};
			// COMPLEX_SESSION patch api
			const payload = {
				code: sessionCode,
				type: API_TYPE.UPDATE,
				payload: kybPayload,
			};
			const res = await postTokenSession(payload);
			if (res?.statusCode === 200) {
				// If `isAllowedToDoItLater` is true, either call `onComplete` for web components or redirect to the login page
				if (isAllowedToDoItLater) {
					if (isWebComponent && typeof onComplete === 'function') {
						onComplete();
					} else if (window.self !== window.top) {
						(window as any).onSessionComplete('redirect');
					} else {
						window.location.href = `${LIQUIDITY_DOMAIN}/login`;
					}
					return;
				}

				if (!isSkipForNow) {
					successNotification('KYB Doc Submitted Successfully');
				}
				const kybResponse = { ...res };
				delete kybResponse?.statusCode;

				setSessionDetails(prev => ({
					...prev,
					nodes: getKybForm && !isDoItLater ? dolaterSuccess : kybResponse,
					fundName: getKybForm && !isDoItLater ? '' : res?.fundName ?? '',
					investingAmount: res?.investingAmount ?? '',
				}));

				if (!getKybForm || isDoItLater) {
					handleNext({
						...sessionDetails,
						nodes: kybResponse,
					});
				}
			} else {
				if (
					res?.statusCode === 0 ||
					res?.statusCode === undefined ||
					res?.statusCode === null
				) {
					warningNotification(
						'No response received. This might be due to a network problem. Please retry.'
					);
				} else {
					errorNotification('Something Went Wrong ');
				}
				setIsShowSkip(true);
			}

			setIsLoading(false);
			setIsSkipLoading(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[
			pipelineId,
			userId,
			nodeIdFromQueryParam,
			currentAction._id,
			sessionDetails,
			sessionCode,
			postTokenSession,
			selectFileName,
			setSessionDetails,
			getKybForm,
			successNotification,
			handleNext,
			setIsShowSkip,
			warningNotification,
			errorNotification,
			optionSelected,
			anyOtherDocumentValueLable,
			anyOtherField,
			isWebComponent,
			onComplete,
			isDoItLater,
		]
	);

	const hiddenFileInput: any = useRef(null);

	const convertFileToBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
		});
	};

	const handleClick = useCallback(
		(valueLable: any) => {
			hiddenFileInput?.current?.click(valueLable?.text);
			const fileObjList: any =
				selectFileName.filter((file: any) => file.id !== valueLable?.text) ||
				[];
			setselectDocName(valueLable);
			fileObjList.push({ id: valueLable?.text, value: valueLable?.value });
			setSelectFileName(fileObjList);
		},
		[selectFileName]
	);

	const handleChange = useCallback(
		async (event: any) => {
			const fileUploaded = event.target?.files[0];
			// pradeep chaurasia : this will validate pdf files only
			if (fileUploaded?.type !== 'application/pdf') {
				errorNotification('Please upload pdf only.');
				return;
			}
			const base64String =
				fileUploaded && ((await convertFileToBase64(fileUploaded)) as string);
			setSelectFileName((prevState: any) => {
				const fileObjList: any =
					prevState.filter((file: any) => file.id !== selectDocName?.text) ||
					[];
				fileObjList.push({
					id: selectDocName?.text,
					value: selectDocName?.value,
					fileName: fileUploaded?.name || '',
					base64: base64String,
				});
				return fileObjList;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectDocName]
	);

	const getFileName = useCallback(
		(documentLabel: string) => {
			const fileObj: any = selectFileName.find(
				(file: any) => file.id === documentLabel
			);
			if (fileObj) return fileObj?.fileName ?? '';

			return '';
		},
		[selectFileName]
	);
	const getNameWithElipses = useCallback(
		(label: string, isRequired?: boolean) => {
			return (
				<div className="elpisis-wrapper">
					<div className="elipsis__label">{label}</div>
					{isRequired && '(Required)'}
				</div>
			);
		},
		[]
	);
	const handelInputTag = useCallback(
		(
			valueLable: any,
			isRequired: boolean,
			index?: any,
			prefilledFileName?: string
		) => (
			<div className="browse_container" key={index || valueLable?.value}>
				{/* Paras: add id for input */}
				<Input
					inputType="text"
					placeholder=""
					value={prefilledFileName || getFileName(valueLable?.text)}
					label={getNameWithElipses(valueLable?.text, isRequired)}
					handleChange={() => {}}
					id="browse_container__input"
					disabled={valueLable?.docUploaded}
				/>
				<div className="browse_container__button_container">
					<Button
						label={
							<span id="buttonid" className="browse_container__label">
								{valueLable?.docUploaded ? 'Uploaded' : 'Browse'}
							</span>
						}
						handleClick={() => handleClick(valueLable)}
						type="button__filled button__filled button__small button__block"
						disabled={valueLable?.docUploaded}
					/>
					<input
						type="file"
						onChange={handleChange}
						ref={hiddenFileInput}
						onClick={(e: any) => (e.currentTarget.value = null)}
						style={{ display: 'none' }}
						accept="application/pdf"
						disabled={valueLable?.docUploaded}
					/>
				</div>
			</div>
		),
		[getFileName, getNameWithElipses, handleChange, handleClick]
	);

	const renderBodyElement = useMemo(
		() => (
			<>
				<div className="kyb-form__sub-header__wrapper">
					<span className="kyb-form__sub-header__title">
						Select your entity type
					</span>
					<span className="kyb-form__sub-header__description">
						Please select your entity type
					</span>
				</div>
				<div className="kyb-form__container">
					{(kybFromEntities ?? [])?.map((key: any, i: any) => (
						<div
							className={`kyb-form__drop-option__container${
								dropOptionFlag && optionSelected?.title === key.title
									? '-select'
									: ''
							}`}
							key={`${key.title}__${i}`.toString()}
						>
							<div
								className="kyb-form__drop-option__title-wrapper"
								onClick={() => handelDropOption(key)}
							>
								<input
									type="radio"
									checked={
										dropOptionFlag && optionSelected?.title === key?.title
									}
									className="react-flow__handle-left"
								/>
								<span className="kyb-form__drop-option__lable-name">
									{key.title?.split(':')}
								</span>
								<i className="ri-arrow-down-s-line" />
							</div>
							{optionSelected?.choices?.length > 0 &&
								dropOptionFlag &&
								optionSelected?.title === key?.title && (
									<div className="kyb-form__drop-option__description-wrapper">
										<span className="kyb-form__drop-option__description-name">
											{key?.choices?.map((valueLable: any, i: any) => (
												<div
													key={`${valueLable?.text}__${i.toString()}`}
													className="kyb-form__drop-option__description-subname"
												>
													{valueLable?.text}
												</div>
											))}
										</span>
									</div>
								)}
						</div>
					))}
				</div>
				<div className="div_form_button">
					<Button
						label="Next"
						handleClick={() => handelKybFormNext()}
						type="button__filled button__filled--primary button__large button__block"
						disabled={dropOptionFlag ? false : true}
					/>
				</div>
			</>
		),
		[
			optionSelected,
			dropOptionFlag,
			handelDropOption,
			handelKybFormNext,
			kybFromEntities,
		]
	);

	const bodyElementEntity = useMemo(
		() => (
			<div className="kyb_form_container">
				<div className="kyb-form__sub-header__wrapper">
					<span className="kyb-form__sub-header__title">
						Upload supporting documents for entity verification
					</span>
					<span className="kyb-form__sub-header__description">
						Please upload all the required document
					</span>
				</div>

				<div className="kyb_div_container__entity">
					{optionSelected?.choices?.map((valueLable: any, i: any) =>
						handelInputTag(valueLable, false, i, valueLable.fileName)
					)}
					{anyOtherField.map((valueLable: any, i: any) =>
						handelInputTag(
							{ ...valueLable, text: `${valueLable.text} ${i}` },
							false,
							i,
							valueLable.fileName
						)
					)}
					{handelInputTag(
						{ text: 'Any other document', value: anyOtherDocumentValueLable },
						false,
						undefined,
						selectFileName.find(
							(file: { id: string }) => file.id === anyOtherDocumentValueLable
						)?.fileName
					)}
				</div>

				<div className="kyb_div_container__button">
					<div className="split-button-container">
						<Button
							label={
								isLoading ? <Loader type="circle" dimension={26} /> : 'Submit'
							}
							disabled={isSubmitDisable}
							handleClick={() => handelKybFormSubmit()}
							type="button__filled button__filled--primary button__large button__block splited-button"
						/>
					</div>
				</div>
			</div>
		),
		[
			optionSelected,
			handelInputTag,
			handelKybFormSubmit,
			isSubmitDisable,
			isLoading,
			anyOtherField,
			anyOtherDocumentValueLable,
			selectFileName,
		]
	);

	const kybBackBtn = useMemo(() => {
		return (
			<>
				{bodyElemFlag && (
					<Button
						type="header-back-btn"
						disabled={isLoading}
						handleClick={handelKybFormBack}
						label={<i className="ri-arrow-left-line"></i>}
					/>
				)}
			</>
		);
	}, [handelKybFormBack, bodyElemFlag, isLoading]);

	const kybskipBtn = useMemo(() => {
		return (
			<>
				{bodyElemFlag && (
					<Button
						type="skip-btn"
						disabled={isLoading}
						handleClick={() =>
							handelKybFormSubmit(true, ...(isDoItLater ? [isDoItLater] : []))
						}
						label={
							<>
								{isDoItLater ? 'Do it later' : 'Skip for now'}
								<i className="ri-arrow-right-s-line"></i>
							</>
						}
					/>
				)}
			</>
		);
	}, [bodyElemFlag, isLoading, isDoItLater, handelKybFormSubmit]);

	if (isSkipLoading) {
		return (
			<div className="loading-steps">
				<Loader />
			</div>
		);
	}

	return (
		<div className="terms-header__container">
			<BodyWrapper
				label={
					<div className="terms-header__wrapper">
						<div className="terms_header">
							<LabelElement
								text={'KYB Form'}
								backBtn={kybBackBtn}
								skipBtn={kybskipBtn}
							/>
						</div>
					</div>
				}
				bodyElement={!bodyElemFlag ? renderBodyElement : bodyElementEntity}
			/>
		</div>
	);
};
